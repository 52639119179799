import React, { useContext } from 'react'

import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../UserContext'

const Hero = () => {
  const navigate = useNavigate()
  const { data } = useContext(UserContext)
  const firstPost = data[0]

  return (
    <div className="hero" onClick={(e) => navigateToDetails(firstPost)}>
      <div className="image__section">
        <img src={firstPost.images[0]} alt="Image1" className="float-image" />
        <img src={firstPost.images[1]} alt="Image2" className="float-image" />
      </div>
      <div className="">
        <Link to="/details">
          <div className="title">
            <h2 style={{ fontSize: '1.5rem', padding: ' 1rem' }}>
              {firstPost.title}
            </h2>
          </div>
          {/* <div className="subtitle">
            <p>IC814 lands Netflix in trouble</p>
          </div> */}
        </Link>
      </div>
    </div>
  )

  function navigateToDetails(content) {
    console.log('COntent', content)

    // console.log("Hello");
    // history.push('/details')
    navigate(`details`, { state: content })
  }
}

export default Hero
