import hamburger from '../asset/hamburger.png'
import React, { useState, useEffect } from 'react'
import SearchBar from '../atoms/SearchBar'
import { useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'

const Navigation = () => {
  useEffect(() => {
    const scrollers = document.querySelectorAll('.scroller')

    // If a user hasn't opted in for recuded motion, then we add the animation
    if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      addAnimation()
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        // add data-animated="true" to every `.scroller` on the page
        scroller.setAttribute('data-animated', true)

        // Make an array from the elements within `.scroller-inner`
        const scrollerInner = scroller.querySelector('.scroller__inner')
        const scrollerContent = Array.from(scrollerInner.children)

        // For each item in the array, clone it
        // add aria-hidden to it
        // add it into the `.scroller-inner`
        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true)
          duplicatedItem.setAttribute('aria-hidden', true)
          scrollerInner.appendChild(duplicatedItem)
        })
      })
    }
  }, [])
  const categories = [
    {
      category: 'News',
      subcategories: [
        'Current Events',
        'Politics',
        'World News',
        'Business',
        'Science',
      ],
    },
    {
      category: 'Entertainment',
      subcategories: [
        'Movies',
        'TV Shows',
        'Music',
        'Celebrity News',
        'Pop Culture',
      ],
    },
    {
      category: 'Lifestyle',
      subcategories: [
        'Health',
        'Fitness',
        'Food',
        'Travel',
        'Home & Living',
        'Fashion',
        'Beauty',
      ],
    },
    {
      category: 'Quizzes',
      subcategories: [
        'Personality Tests',
        'Trivia',
        'Pop Culture Quizzes',
        'Opinion Polls',
      ],
    },
    {
      category: 'Buzz',
      subcategories: [
        'Viral Stories',
        'Internet Trends',
        'Funny',
        'Heartwarming',
      ],
    },
    {
      category: 'Life',
      subcategories: [
        'Relationships',
        'Self-Improvement',
        'Personal Finance',
        'Career Advice',
      ],
    },
    {
      category: 'Shopping',
      subcategories: [
        'Product Reviews',
        'Gift Guides',
        'Deals',
        'Fashion Finds',
        'Tech Gadgets',
      ],
    },
    {
      category: 'Video',
      subcategories: [
        'Short Films',
        'Documentaries',
        'Viral Videos',
        'Tutorials',
        'Entertainment',
      ],
    },
  ]

  const navigate = useNavigate()


  return (
    // Ads
    <>
      <div className="navigation">
        {/* Hamburger Brand Name */}
        <div style={{ display: 'flex', marginLeft: '10px' }}>
          <div className="hamburger">
            <img src={hamburger} alt="hamburger" />
          </div>
          {/* Logo */}
          <div className="brand-name" onClick={navigateToHome}>
            <h2 className="" style={{ color: '#e40c78', fontSize: '1.6rem' }}>
              Funxy
            </h2>
          </div>
        </div>

        {/* Categories */}
        <div className="categories-web"></div>

        {/* SignIn */}

        <div className="sign-in">
          <button className="signin-button">Sign In</button>
          {/* Search */}

          <div className="search">{/* <SearchBar /> */}</div>
        </div>

        {/* Funny/Trending */}
      </div>

      {/* Category */}
      {/* <div class="scroller" data-speed="slow">
        <ul className="tag-list scroller__inner">
          {categories.map((category, index) => (
            <li>{category.category}</li>
          ))}
        </ul>
      </div> */}

      <Swiper
        className="wrapper__categories"
        spaceBetween={3}
        slidesPerView={3}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
      >
        {categories.map((category, index) => (
          <SwiperSlide className="categories">{category.category}</SwiperSlide>
        ))}
      </Swiper>
    </>
  )
  function navigateToHome() {
    console.log('Going Back')
    navigate('/')
  }
}

export default Navigation
