export const collection = [
  {
    id: '1',
    title: 'IC 814: A Dramatic Retelling of a Hijacking Crisis',
    subTitle: '',
    content: [
      `IC814 is a 2023 Indian Hindi-language film directed by Sanjay
              Gadhvi and produced by Vishal Rana. The film is a dramatic
              portrayal of the real-life hijacking incident involving Indian
              Airlines Flight IC814, which was seized by terrorists on December
              24, 1999.`,
      `The movie recounts the harrowing events of the hijacking, focusing
              on the intense negotiations between the hijackers and the Indian
              government. The plot delves into the high-stakes crisis as the
              authorities work to secure the safe release of the passengers held
              hostage. The film explores themes of terrorism, political
              pressure, and the personal stories of those affected by the
              ordeal, offering a gripping and emotional narrative of a pivotal
              moment in Indian history. This dramatization aims to shed light on
              the complexities and tensions of the crisis, highlighting the
              resilience and bravery of the individuals involved.`,
    ],
    images: [
      'https://raw.githubusercontent.com/llassan/Image-Library/main/Kandhaar-Hijack.webp',
      'https://github.com/llassan/Image-Library/blob/main/ic814.jpg?raw=true',
    ],
    infringement: ['Netflix/IC814 Movie Page', 'Netflix/IC814 Movie Page'],
    createdAt: Date.now(),
  },
  {
    id: '2',
    title: 'नीरज चोपड़ा: एक सुनहरी यात्रा - ओलंपिक स्वर्ण पदक विजेता',
    subTitle: '',
    content: [
      `नीरज चोपड़ा के बारे में रोचक तथ्य
        भारत के ओलंपिक स्वर्ण पदक विजेता नीरज चोपड़ा के बारे में कुछ दिलचस्प तथ्य:
        गांव का लड़का: नीरज चोपड़ा का जन्म पंजाब के पटियाला जिले के एक छोटे से गांव, खंडरा में हुआ था।
        शुरुआती दिन: उन्होंने अपने स्कूल के खेल दिवस पर पहली बार भाला फेंका और तुरंत ही इस खेल में रुचि ले ली।
        सैनिक स्कूल में प्रशिक्षण: नीरज ने चंडीगढ़ के सैनिक स्कूल में प्रशिक्षण प्राप्त किया, जहां उन्हें भाला फेंक में मार्गदर्शन मिला।`,
      `मशीन लर्निंग और डीप लर्निंग जैसी तकनीकों ने कई क्षेत्रों में प्रगति की है, जिनमें स्वास्थ्य, वित्त और परिवहन शामिल हैं।
      इनका प्रभाव आने वाले वर्षों में और अधिक बढ़ने की उम्मीद है।`,
    ],
    images: [
      'https://github.com/llassan/Image-Library/blob/main/neeraj-chopra-javelin.jpg?raw=true',
      'https://github.com/llassan/Image-Library/blob/main/neeraj-chopra-gold-medal.jpg?raw=true'
    ],
  },
  {
    id: '3',
    title: 'कंप्यूटर विज्ञान में नवीनतम विकास',
    content: [
      `कंप्यूटर विज्ञान का क्षेत्र निरंतर विकासशील है। नवीनतम तकनीकों ने जीवन के हर क्षेत्र में क्रांति ला दी है।
      आर्टिफिशियल इंटेलिजेंस, मशीन लर्निंग, और डेटा साइंस जैसे क्षेत्रों में प्रगति ने न केवल उद्योगों को रूपांतरित किया है,
      बल्कि रोजमर्रा के जीवन को भी बदल दिया है।`,
      `क्लाउड कंप्यूटिंग ने डेटा स्टोरेज और प्रोसेसिंग को बहुत आसान और तेज़ बना दिया है। अब कंपनियां अपने बड़े-बड़े
      डेटा सेट्स को आसानी से मैनेज कर सकती हैं, जो पहले असंभव प्रतीत होता था। यह न केवल कार्यक्षमता को बढ़ाता है,
      बल्कि लागत में भी कटौती करता है।`,
    ],
    images: [
      'https://github.com/llassan/Image-Library/blob/main/comouter-science-left.jpg?raw=true', // Replace with actual image URLs
      'https://github.com/llassan/Image-Library/blob/main/computer-science01.jpg?raw=true',
    ],
  },
  {
    id: '4',
    title: 'आर्टिफिशियल इंटेलिजेंस का भविष्य',
    content: [
      `आर्टिफिशियल इंटेलिजेंस का भविष्य बहुत उज्ज्वल दिख रहा है। नई तकनीकें न केवल मशीनों को अधिक सक्षम बना रही हैं,
      बल्कि नए रोजगार के अवसर भी प्रदान कर रही हैं।`,
      `मशीन लर्निंग और डीप लर्निंग जैसी तकनीकों ने कई क्षेत्रों में प्रगति की है, जिनमें स्वास्थ्य, वित्त और परिवहन शामिल हैं।
      इनका प्रभाव आने वाले वर्षों में और अधिक बढ़ने की उम्मीद है।`,
    ],
    images: [
      'https://github.com/llassan/Image-Library/blob/main/ai_alien.jpg?raw=true',
      'https://github.com/llassan/Image-Library/blob/main/ai_girl.jpg?raw=true',
    ],
  },
  {
    id: '5',
    title: 'नीरज चोपड़ा: एक सुनहरी यात्रा - ओलंपिक स्वर्ण पदक विजेता',
    subTitle: '',
    content: [
      `नीरज चोपड़ा के बारे में रोचक तथ्य
        भारत के ओलंपिक स्वर्ण पदक विजेता नीरज चोपड़ा के बारे में कुछ दिलचस्प तथ्य:
        गांव का लड़का: नीरज चोपड़ा का जन्म पंजाब के पटियाला जिले के एक छोटे से गांव, खंडरा में हुआ था।
        शुरुआती दिन: उन्होंने अपने स्कूल के खेल दिवस पर पहली बार भाला फेंका और तुरंत ही इस खेल में रुचि ले ली।
        सैनिक स्कूल में प्रशिक्षण: नीरज ने चंडीगढ़ के सैनिक स्कूल में प्रशिक्षण प्राप्त किया, जहां उन्हें भाला फेंक में मार्गदर्शन मिला।`,
      `मशीन लर्निंग और डीप लर्निंग जैसी तकनीकों ने कई क्षेत्रों में प्रगति की है, जिनमें स्वास्थ्य, वित्त और परिवहन शामिल हैं।
      इनका प्रभाव आने वाले वर्षों में और अधिक बढ़ने की उम्मीद है।`,
    ],
    images: [
      'https://github.com/llassan/Image-Library/blob/main/neeraj-chopra-javelin.jpg?raw=true',
      'https://github.com/llassan/Image-Library/blob/main/neeraj-chopra-gold-medal.jpg?raw=true'
    ],
  },
  {
    id: '6',
    title: 'कंप्यूटर विज्ञान में नवीनतम विकास',
    content: [
      `कंप्यूटर विज्ञान का क्षेत्र निरंतर विकासशील है। नवीनतम तकनीकों ने जीवन के हर क्षेत्र में क्रांति ला दी है।
      आर्टिफिशियल इंटेलिजेंस, मशीन लर्निंग, और डेटा साइंस जैसे क्षेत्रों में प्रगति ने न केवल उद्योगों को रूपांतरित किया है,
      बल्कि रोजमर्रा के जीवन को भी बदल दिया है।`,
      `क्लाउड कंप्यूटिंग ने डेटा स्टोरेज और प्रोसेसिंग को बहुत आसान और तेज़ बना दिया है। अब कंपनियां अपने बड़े-बड़े
      डेटा सेट्स को आसानी से मैनेज कर सकती हैं, जो पहले असंभव प्रतीत होता था। यह न केवल कार्यक्षमता को बढ़ाता है,
      बल्कि लागत में भी कटौती करता है।`,
    ],
    images: [
      'https://github.com/llassan/Image-Library/blob/main/comouter-science-left.jpg?raw=true', // Replace with actual image URLs
      'https://github.com/llassan/Image-Library/blob/main/computer-science01.jpg?raw=true',
    ],
  },
  {
    id: '7',
    title: 'आर्टिफिशियल इंटेलिजेंस का भविष्य',
    content: [
      `आर्टिफिशियल इंटेलिजेंस का भविष्य बहुत उज्ज्वल दिख रहा है। नई तकनीकें न केवल मशीनों को अधिक सक्षम बना रही हैं,
      बल्कि नए रोजगार के अवसर भी प्रदान कर रही हैं।`,
      `मशीन लर्निंग और डीप लर्निंग जैसी तकनीकों ने कई क्षेत्रों में प्रगति की है, जिनमें स्वास्थ्य, वित्त और परिवहन शामिल हैं।
      इनका प्रभाव आने वाले वर्षों में और अधिक बढ़ने की उम्मीद है।`,
    ],
    images: [
      'https://github.com/llassan/Image-Library/blob/main/ai_alien.jpg?raw=true',
      'https://github.com/llassan/Image-Library/blob/main/ai_girl.jpg?raw=true',
    ],
  },
  {
    id: '6',
    title: 'कंप्यूटर विज्ञान में नवीनतम विकास',
    content: [
      `कंप्यूटर विज्ञान का क्षेत्र निरंतर विकासशील है। नवीनतम तकनीकों ने जीवन के हर क्षेत्र में क्रांति ला दी है।
      आर्टिफिशियल इंटेलिजेंस, मशीन लर्निंग, और डेटा साइंस जैसे क्षेत्रों में प्रगति ने न केवल उद्योगों को रूपांतरित किया है,
      बल्कि रोजमर्रा के जीवन को भी बदल दिया है।`,
      `क्लाउड कंप्यूटिंग ने डेटा स्टोरेज और प्रोसेसिंग को बहुत आसान और तेज़ बना दिया है। अब कंपनियां अपने बड़े-बड़े
      डेटा सेट्स को आसानी से मैनेज कर सकती हैं, जो पहले असंभव प्रतीत होता था। यह न केवल कार्यक्षमता को बढ़ाता है,
      बल्कि लागत में भी कटौती करता है।`,
    ],
    images: [
      'https://github.com/llassan/Image-Library/blob/main/comouter-science-left.jpg?raw=true', // Replace with actual image URLs
      'https://github.com/llassan/Image-Library/blob/main/computer-science01.jpg?raw=true',
    ],
  },
  {
    id: '7',
    title: 'आर्टिफिशियल इंटेलिजेंस का भविष्य',
    content: [
      `आर्टिफिशियल इंटेलिजेंस का भविष्य बहुत उज्ज्वल दिख रहा है। नई तकनीकें न केवल मशीनों को अधिक सक्षम बना रही हैं,
      बल्कि नए रोजगार के अवसर भी प्रदान कर रही हैं।`,
      `मशीन लर्निंग और डीप लर्निंग जैसी तकनीकों ने कई क्षेत्रों में प्रगति की है, जिनमें स्वास्थ्य, वित्त और परिवहन शामिल हैं।
      इनका प्रभाव आने वाले वर्षों में और अधिक बढ़ने की उम्मीद है।`,
    ],
    images: [
      'https://github.com/llassan/Image-Library/blob/main/ai_alien.jpg?raw=true',
      'https://github.com/llassan/Image-Library/blob/main/ai_girl.jpg?raw=true',
    ],
  },
]

// src/contentData.js

export const contentSections = [
  {
    title: 'कंप्यूटर विज्ञान में नवीनतम विकास',
    content: [
      `कंप्यूटर विज्ञान का क्षेत्र निरंतर विकासशील है। नवीनतम तकनीकों ने जीवन के हर क्षेत्र में क्रांति ला दी है।
      आर्टिफिशियल इंटेलिजेंस, मशीन लर्निंग, और डेटा साइंस जैसे क्षेत्रों में प्रगति ने न केवल उद्योगों को रूपांतरित किया है,
      बल्कि रोजमर्रा के जीवन को भी बदल दिया है।`,
      `क्लाउड कंप्यूटिंग ने डेटा स्टोरेज और प्रोसेसिंग को बहुत आसान और तेज़ बना दिया है। अब कंपनियां अपने बड़े-बड़े
      डेटा सेट्स को आसानी से मैनेज कर सकती हैं, जो पहले असंभव प्रतीत होता था। यह न केवल कार्यक्षमता को बढ़ाता है,
      बल्कि लागत में भी कटौती करता है।`,
    ],
    images: [
      'https://example.com/path/to/image1.jpg', // Replace with actual image URLs
      'https://example.com/path/to/image2.jpg',
    ],
  },
  {
    title: 'आर्टिफिशियल इंटेलिजेंस का भविष्य',
    content: [
      `आर्टिफिशियल इंटेलिजेंस का भविष्य बहुत उज्ज्वल दिख रहा है। नई तकनीकें न केवल मशीनों को अधिक सक्षम बना रही हैं,
      बल्कि नए रोजगार के अवसर भी प्रदान कर रही हैं।`,
      `मशीन लर्निंग और डीप लर्निंग जैसी तकनीकों ने कई क्षेत्रों में प्रगति की है, जिनमें स्वास्थ्य, वित्त और परिवहन शामिल हैं।
      इनका प्रभाव आने वाले वर्षों में और अधिक बढ़ने की उम्मीद है।`,
    ],
    images: [
      'https://example.com/path/to/image3.jpg', // Replace with actual image URLs
      'https://example.com/path/to/image4.jpg',
      'https://example.com/path/to/image5.jpg',
    ],
  },
]
