import './App.css'

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Details from './pages/Details'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { UserProvider } from './UserContext';
import NoMatch from './pages/NoMatch'

function App() {
  const categories = [
    'Buzz',
    'Cricket',
    'Religion/Spirituality(Mythology)',
    'Books/ Poetry/Literature',
    'Nutrition/Health/Lifestyle',
    'Home Decor',
    'Reels',
    'Deals',
    'Sports',
    'Relationship/',
    'Shopping',
    'Today In History',
    'Curious',
    'Interesting',
    'Quizzes',
    'Lifestyle',
    'News',
    'Entertainment',
    'BigBoss',
    'Women in Power',
    'In Hindi Share & Finance/',
    'Hindi mein news paris mein ye khula',
  ]
  return (
    <UserProvider>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="details" element={<Details />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </Router>
    </UserProvider>
  )
}

export default App
