import React, { useContext, useEffect } from 'react'
import Navigation from '../components/Navigation'
import { Link } from 'react-router-dom'
import './style/Details.css'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../UserContext'
import { useSwipeable } from 'react-swipeable'

const Details = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const post = location.state
  const { data } = useContext(UserContext)
  console.log(data);


  // Set up swipe handlers
  const handlers = useSwipeable({
    onSwipedRight: () => navigate(-1), // Navigate back when swiping right
    preventDefaultTouchmoveEvent: true,
    trackMouse: true, // Allows swipe detection via mouse as well as touch
  })

    // Retrieve the card data from location.state
    const card = location.state;

    // Handle the case where state is missing (e.g., page refresh)
    if (!card) {
      // Optional: Redirect to home or a fallback page
      navigate('/'); // Redirect to home page
      return null;   // Prevent rendering if there's no data
    }



  return (
    <div {...handlers}>
      <Navigation />
      <div className="details">
        <Link to="/">
          <h2
            style={{
              fontSize: '1.5rem',
              padding: ' 1rem',
            }}
          >
            {post.title}
          </h2>
        </Link>
        <div class="containers main-content">
          <img src={post.images[0]} alt="Image2" />
          <span className="infringement">
            {post.infringement && post.infringement[0]}
          </span>
          <div className="movie-details">
            <p>{post.content[0]}</p>
            <img src={post.images[1]} alt="Image1" />
            <span className="infringement">
              {post.infringement && post.infringement[1]}
            </span>
            <p>{post.content[1]}</p>
          </div>
        </div>

        {/* <footer>
          <p>&copy; 2024 IC814 Movie Page</p>
        </footer> */}
      </div>
    </div>
  )
}

export default Details
