import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../UserContext'
import { Link } from 'react-router-dom'

const Trending = () => {
  const navigate = useNavigate()
  const { data } = useContext(UserContext)

  return (
    <>
      {data.slice(1,4).map((content) => {
        return (
          <div className="card" onClick={(e) => navigateToDetails(content)}>
            <div className="images">
              <img
                src={`${content.images[0]}`}
                alt="Image1"
                className="float-image"
              />
              <img
                src={`${content.images[1]}`}
                alt="Image2"
                className="float-image"
              />
            </div>
            <div className="heading">
              <Link to="/">
                <p className="trending__banner ">
                  <span className="waving-text">&#10166;</span>
                  &nbsp;Trending
                </p>
                <h6 style={{ marginTop: '8px' }} className="">
                  {content.title}
                </h6>
              </Link>
            </div>
          </div>
        )
      })}
    </>
  )
  function navigateToDetails(content) {
    navigate(`details`, { state: content })
  }
}

export default Trending
