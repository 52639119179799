import React, { useContext, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Virtual, Navigation, Pagination } from 'swiper/modules'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { UserContext } from '../UserContext'
import { useNavigate } from 'react-router-dom'

const TrendingNow = () => {
  const [swiperRef, setSwiperRef] = useState(null)
  const navigate = useNavigate()
  const { data } = useContext(UserContext)
  return (
    <>
      <h4 style={{ marginLeft: '10px', color: '#e40c78' }}>Trending Now</h4>
      {/* Trending Now */}

      <Swiper
        // className="trending-now-wrapper"
        modules={[Virtual, Navigation, Pagination]}
        onSwiper={setSwiperRef}
        slidesPerView={1}
        centeredSlides={false}
        spaceBetween={10}
        // pagination={{
        //   type: 'fraction',
        // }}
        // navigation={true}
        virtual
      >
        {data.slice(5).map((content) => {
          return (
            <SwiperSlide
              className="trending-now"
              onClick={(e) => navigateToDetails(content)}
            >
              <div className="image__top">
                <img src={content.images[0]} alt="left" />
                <img src={content.images[1]} alt="right" />
              </div>
              <div className="text">
                <h5>{content.title}</h5>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
      {/* Infinite Scrolling */}
      {/* <div className="scroller" data-direction="right" data-speed="slow">
        <div className="scroller__inner">
          <div className="card">
            <div className="image__top">
              <img src="https://i.pravatar.cc/150?img=1" alt="" />
              <img src="https://i.pravatar.cc/150?img=2" alt="" />
            </div>
            <div className="text">
              <h4>
                Are Your Hot Chocolate Preferences Normal Or Simply Weird?
              </h4>
            </div>
          </div>
          <div className="card">
            <div className="image__top">
              <img src="https://i.pravatar.cc/150?img=3" alt="" />
              <img src="https://i.pravatar.cc/150?img=4" alt="" />
            </div>
            <div className="text">
              <h4>
                Are Your Hot Chocolate Preferences Normal Or Simply Weird?
              </h4>
            </div>
          </div>
          <div className="card">
            <div className="image__top">
              <img src="https://i.pravatar.cc/150?img=5" alt="" />
              <img src="https://i.pravatar.cc/150?img=6" alt="" />
            </div>
            <div className="text">
              <h4>
                Are Your Hot Chocolate Preferences Normal Or Simply Weird?
              </h4>
            </div>
          </div>
          <div className="card">
            <div className="image__top">
              <img src="https://i.pravatar.cc/150?img=3" alt="" />
              <img src="https://i.pravatar.cc/150?img=4" alt="" />
            </div>
            <div className="text">
              <h4>
                Are Your Hot Chocolate Preferences Normal Or Simply Weird?
              </h4>
            </div>
          </div>
          <div className="card">
            <div className="image__top">
              <img src="https://i.pravatar.cc/150?img=5" alt="" />
              <img src="https://i.pravatar.cc/150?img=6" alt="" />
            </div>
            <div className="text">
              <h4>
                Are Your Hot Chocolate Preferences Normal Or Simply Weird?
              </h4>
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
  function navigateToDetails(content) {
    navigate(`details`, { state: content })
  }
}

export default TrendingNow
