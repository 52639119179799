import React, { createContext, useState } from 'react'
import { collection } from './asset/content/data'

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [data, setData] = useState(collection)

  return (
    <UserContext.Provider value={{ data, setData }}>
      {children}
    </UserContext.Provider>
  )
}
