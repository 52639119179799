import React from 'react'
import Hero from '../components/Hero'
import Navigation from '../components/Navigation'
import TrendingNow from '../components/TrendingNow'
import Trending from '../components/Trending'

const Home = () => {
  return (
    <>
      <Navigation />
      <Hero />
      <Trending />
      <TrendingNow />
    </>
  )
}

export default Home

